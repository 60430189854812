import React from 'react';
import classes from './Login.module.scss';
import { userData } from './slices/userSlice';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Spinner from '../shared/ui-components/spinner/spinner';
import GoogleLoginPage from './GoogleLoginPage';

export default function Login(props) {
  const user = useSelector(userData);
  const isPending = user.status === 'loading';
  return (
    <div className={classes.LoginPage}>
      {isPending && <Spinner />}
      {user.userSignedIn ? (
        <Redirect
          to={{
            pathname: props.location.prevPath,
          }}
        />
      ) : (
        <GoogleLoginPage previousLocation={props.location} />
      )}
    </div>
  );
}
