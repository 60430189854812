import React from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { 
  setEmail, 
  setFirstName, 
  setLastName, 
  setProfilePic, 
  storeUserToken,
  verifyUserCredentials,
} from './slices/userSlice';
import { useHistory } from 'react-router';

export default function GoogleLoginPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  
  // Google SigIn Success
  const responseGoogleSuccess = (response) => {

    // Set Email-ID from OAuth
    const email = response.profileObj.email;
    dispatch(setEmail(email))
    
    // Set First Name from OAuth
    const firstName = response.profileObj.givenName;
    dispatch(setFirstName(firstName))
    
    // Set Last Name from OAuth
    const lastName = response.profileObj.fmailyName;
    dispatch(setLastName(lastName))
    
    // Set Profile-Pic URL from Oauth
    const profilePic = response.profileObj.imageUrl;
    dispatch(setProfilePic(profilePic))
    
    // Store Oauth-tokenId in localStorage (JWT practice)
    const userToken = response.tokenId
    dispatch(storeUserToken(userToken))
    
    // SignIn user
    dispatch(verifyUserCredentials(userToken))
    history.push(props.previousLocation);
  };

  // Google Login Failed
  const responseGoogleFailed = (response) => { alert(`User Login Failed !!!\n`, response) };

  return (
    <React.Fragment>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
        buttonText='Login'
        onSuccess={responseGoogleSuccess}
        onFailure={responseGoogleFailed}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
      />
    </React.Fragment>
  );
}